import { useCallback, useEffect, useState } from 'react'
import { Button } from 'src/components/restructure/ui/Button/Button'
import Icon from 'src/components/ui/Icon'
import { Picture } from 'src/components/ui/Image'
import { useElementOnScreen } from 'src/hooks/useElementOnScreen'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { Container } from 'src/components/department/Container'
import { Link } from 'gatsby'
import { InView } from 'react-intersection-observer'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'

import type { TertiaryBannerData, Banner } from './types'
import { useTertiaryBanner } from './useTertiaryBanner'
import { dispatchToDatalayer, sendPromotionClick } from './utils'

export const TertiaryBanner = (props: TertiaryBannerData) => {
  const {
    enableSession,
    sessionTitle,
    sessionButton,
    banners,
    section,
    locationOnPage,
  } = props

  const standardObject = getStandardObject()

  const { containerRef, isVisible } = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  })

  const [hasBeenViewed, setHasBeenViewed] = useState(false)

  const sendAmplitudeClickEvent = (banner: Banner, index: number) => {
    if (!locationOnPage || !section) {
      return
    }

    const featureObject = getFeatureObject({
      section,
      locationOnPage,
      name: banner?.productName ?? '',
    })

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData: {
        ...standardObject,
        ...featureObject,
        'promotional element name': banner?.productName ?? '',
        'promotional element index': index ?? 0,
      },
    })
  }

  const sendEventTrackerCta = useCallback(() => {
    sendEvent(
      makeEventTrack({
        eventAction: `${sessionTitle}`,
        eventPage: 'Home',
      })
    )
  }, [sessionTitle])

  useEffect(() => {
    if (hasBeenViewed || !isVisible) {
      return
    }

    dispatchToDatalayer(banners)
    setHasBeenViewed(true)
  }, [sessionTitle, hasBeenViewed, isVisible, banners])

  const sendPromoFeatureViewedEvent = (item: Banner) => {
    if (!locationOnPage || !section) {
      return
    }

    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        section,
        locationOnPage,
        name: item.productName ?? '',
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  const { validateProps } = useTertiaryBanner()
  const propsIsValid = validateProps(props)

  if (!propsIsValid) {
    return null
  }

  if (!enableSession || banners.length < 1) {
    return null
  }

  const [firstBanner, ...otherBanners] = banners

  return (
    <Container
      className="my-[40px] restructure-tablet:my-[56px] restructure-small-desktop:my-[80px]"
      data-testid="tertiary-banner-container"
    >
      <div className="hidden restructure-tablet:flex items-center justify-between mb-[40px]">
        <h2 className="font-inter text-font-size-8 font-medium">
          {sessionTitle}
        </h2>
        <Link
          title={sessionButton.text}
          to={sessionButton.linkURL}
          className="flex items-center gap-2 font-inter text-font-size-4 font-semibold text-restructure-action"
          onClick={sendEventTrackerCta}
          data-testid="tertiary-banner-event-tracker"
        >
          {sessionButton.text}
          <Icon name="ArrowRight" width={24} height={24} />
        </Link>
      </div>
      <Link
        to={firstBanner.linkUrl}
        title={`${firstBanner.productName}`}
        data-testid="tertiary-banner-promo"
        onClick={() => {
          sendPromotionClick(firstBanner, 1)
          sendAmplitudeClickEvent(firstBanner, 0)
        }}
      >
        <article
          className="bg-restructure-background-neutral-2 rounded-md overflow-hidden flex flex-col w-full min-w-[328px] restructure-tablet:!min-h-[310px] restructure-tablet:w-[794px] restructure-medium-desktop:!min-h-[500px] restructure-tablet:flex-row restructure-tablet:items-center restructure-medium-desktop:w-[1280px] relative z-10"
          style={{ minHeight: '328px' }}
          ref={containerRef}
          data-testid="tertiary-banner"
        >
          <div
            className="z-10 px-4 pt-6 flex flex-col gap-2 restructure-tablet:max-w-[350px] restructure-tablet:pt-0 restructure-tablet:px-0 restructure-tablet:h-[253px] restructure-medium-desktop:h-[277px] restructure-tablet:ml-8 restructure-medium-desktop:ml-[4.5rem]"
            style={{ minHeight: '166px' }}
          >
            <h3 className="font-inter text-restructure-action text-font-size-2 font-semibold restructure-tablet:text-font-size-3 restructure-medium-desktop:text-font-size-3">
              {firstBanner.tag}
            </h3>
            <h3 className="font-inter text-restructure-secondary text-font-size-4 font-normal hidden restructure-tablet:block">
              {firstBanner.label}
            </h3>
            <h3 className="font-inter font-normal text-restructure-secondary  text-font-size-4 restructure-tablet:text-font-size-6 restructure-tablet:font-medium restructure-medium-desktop:text-font-size-7 line-clamp-2">
              {firstBanner.productName}
            </h3>
            <h3 className="font-inter font-medium text-font-size-6 text-restructure-primary restructure-tablet:text-font-size-8 mb-4 restructure-tablet:mt-2 restructure-tablet:mb-6">
              {firstBanner.productPrice}
            </h3>
            <Button.Root
              variant="convertion"
              sizeAsBreakpoints
              className="max-w-[127px] mt-auto restructure-tablet:max-w-[159px] restructure-medium-desktop:max-w-[229px] h-8"
            >
              {firstBanner.buttonText}
              <Button.Icon
                name="ArrowRight"
                width={16}
                height={16}
                className="restructure-tablet:w-5 restructure-tablet:h-5 restructure-medium-desktop:w-6 restructure-medium-desktop:h-6"
              />
            </Button.Root>
          </div>
          <InView
            as="div"
            threshold={0.7}
            triggerOnce
            onChange={(inView) => {
              if (inView) {
                sendPromoFeatureViewedEvent(firstBanner)
              }
            }}
            className="w-full h-full"
          >
            <Picture
              className="absolute left-0 bottom-0 w-full h-full object-cover"
              data-banner-type="Four Banners"
              sources={[
                {
                  src: firstBanner.image?.mobile ?? '',
                  width: 400,
                  height: 400,
                  media: '(max-width: 600px)',
                },
                {
                  src: firstBanner.image?.tablet ?? '',
                  width: 794,
                  height: 310,
                  media: '(max-width: 900px)',
                },
                {
                  src: firstBanner.image?.desktop ?? '',
                  width: 1280,
                  height: 500,
                  media: '(min-width: 901px)',
                },
              ]}
              img={{
                loading: 'lazy',
                src: firstBanner.image?.desktop ?? '',
                width: 1280,
                height: 500,
                alt: firstBanner.image?.altImage ?? '',
                title: firstBanner.productName,
                'data-id': firstBanner.productName,
              }}
            />
          </InView>
        </article>
      </Link>
      <article className="flex flex-col gap-3 w-full restructure-tablet:flex-row restructure-medium-desktop:gap-6 mt-3 restructure-medium-desktop:mt-6">
        {otherBanners.map((infoBanner, index) => (
          <InView
            as="div"
            threshold={0.7}
            triggerOnce
            onChange={(inView) => {
              if (inView) {
                sendPromoFeatureViewedEvent(infoBanner)
              }
            }}
            key={`product-promo-${infoBanner.linkUrl}-${infoBanner.productName}`}
            className="bg-restructure-background-neutral-2 w-full min-w-[328px] rounded-md overflow-hidden flex-1 flex flex-row items-center relative z-10 restructure-tablet:min-w-[391px] restructure-tablet:!min-h-[223px] restructure-medium-desktop:min-w-[628px]  restructure-medium-desktop:!min-h-[360px]"
            style={{ minHeight: '180px' }}
          >
            <Link
              to={infoBanner.linkUrl}
              title={`${infoBanner.productName}`}
              onClick={() => {
                sendPromotionClick(infoBanner, index + 2)
                sendAmplitudeClickEvent(infoBanner, index + 1)
              }}
              className="bg-restructure-background-neutral-2 w-full min-w-[328px] rounded-md overflow-hidden flex-1 flex flex-row items-center relative z-10 restructure-tablet:min-w-[391px] restructure-tablet:!min-h-[223px] restructure-medium-desktop:min-w-[628px]  restructure-medium-desktop:!min-h-[360px]"
            >
              <div className="z-10 pl-4 pt-6 pb-4 flex flex-col gap-2 max-w-[180px] min-h-[137px] restructure-tablet:p-0 restructure-tablet:ml-8  restructure-tablet:gap-1 restructure-tablet:max-w-[192px] restructure-medium-desktop:max-w-[315px] restructure-medium-desktop:gap-2 restructure-medium-desktop:ml-10 restructure-tablet:h-[175px] restructure-medium-desktop:h-[253px]">
                <h3 className="font-inter text-restructure-action text-font-size-2 restructure-tablet:text-font-size-2 font-semibold restructure-medium-desktop:text-font-size-3">
                  {infoBanner.tag}
                </h3>
                <h3 className="font-inter text-restructure-secondary text-font-size-4 font-normal hidden restructure-tablet:block restructure-tablet:text-font-size-2 restructure-medium-desktop:text-font-size-4">
                  {infoBanner.label}
                </h3>
                <h3 className="font-inter font-normal text-restructure-secondary  text-font-size-3 restructure-tablet:text-font-size-4 restructure-medium-desktop:text-font-size-6 restructure-tablet:mb-0  restructure-medium-desktop:mb-2 line-clamp-2">
                  {infoBanner.productName}
                </h3>
                <h3 className="font-inter font-medium text-font-size-4 text-restructure-primary  restructure-tablet:text-font-size-5  restructure-tablet:font-semibold restructure-tablet:mb-3 restructure-medium-desktop:text-font-size-8 restructure-medium-desktop:font-medium">
                  {infoBanner.productPrice}
                </h3>
                <Button.Root
                  variant="secondary"
                  className="mt-auto  restructure-maxMobile:border-none restructure-maxMobile:justify-start restructure-maxMobile:gap-4 restructure-maxMobile:text-restructure-action restructure-maxMobile:pl-0 restructure-tablet:max-w-[120px] restructure-medium-desktop:max-w-[127px] restructure-medium-desktop:min-h-[48px]"
                >
                  {infoBanner.buttonText}
                  <Button.Icon
                    name="ArrowRight"
                    width={16}
                    height={16}
                    className="restructure-medium-desktop:w-5 restructure-medium-desktop:h-5"
                  />
                </Button.Root>
              </div>
              <div className="w-full h-full">
                <Picture
                  className="absolute left-0 bottom-0 w-full h-full object-cover"
                  data-banner-type="Four Banners"
                  sources={[
                    {
                      src: infoBanner.image?.mobile ?? '',
                      width: 328,
                      height: 180,
                      media: '(max-width: 600px)',
                    },
                    {
                      src: infoBanner.image?.tablet ?? '',
                      width: 391,
                      height: 223,
                      media: '(max-width: 900px)',
                    },
                    {
                      src: infoBanner.image?.desktop ?? '',
                      width: 628,
                      height: 360,
                      media: '(min-width: 901px)',
                    },
                  ]}
                  img={{
                    loading: 'lazy',
                    src: infoBanner.image?.desktop ?? '',
                    width: 628,
                    height: 360,
                    alt: infoBanner.image?.altImage ?? '',
                    title: infoBanner.productName,
                    'data-id': infoBanner.productName,
                  }}
                />
              </div>
            </Link>
          </InView>
        ))}
      </article>

      {sessionButton.text && (
        <a
          title={sessionButton.text}
          href={sessionButton.linkURL}
          className="mt-5 flex items-center gap-2 font-inter text-font-size-4 font-semibold text-restructure-action restructure-tablet:hidden"
          onClick={sendEventTrackerCta}
        >
          {sessionButton.text} <Icon name="ArrowRight" width={24} height={24} />
        </a>
      )}
    </Container>
  )
}
